/* The code is importing the `createGlobalStyle` function from the `styled-components` library. */
import { createGlobalStyle } from "styled-components";
// poppins
import "@fontsource/poppins"; // Defaults to weight 400
import "@fontsource/poppins/400.css"; // Specify weight
import "@fontsource/poppins/400-italic.css"; // Specify weight and style
// inter
import "@fontsource/inter"; // Defaults to weight 400
import "@fontsource/inter/400.css"; // Specify weight
// import "@fontsource/inter/400-italic.css"; // Specify weight and style

export default createGlobalStyle`
  *{
    font-style: normal;
    line-height: normal;
    scroll-behavior: smooth;
  }

    body {
        margin: 0; line-height: normal;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        background: #EDF0FA;
        // font-family: Poppins;

      font-family: 'Poppins', sans-serif; /* Fallback font */
    }

    a {
        font-family: Poppins;
    }

    .marquee_main_slider_premature_ejaculation {
      z-index: 1;
    }

    ::-webkit-scrollbar {
      width: 2px;
    }

    ::-webkit-scrollbar-track {
      background: none;
    }

    ::-webkit-scrollbar-thumb {
      background: #fff;
    }

  img {
    filter: none !important;
  }

  .image {
  width: 100%;
  height: auto;
}
`;
