/**
 * The `LazyLoader` function is a higher-order component that wraps a component in a `React.Suspense`
 * component to enable lazy loading.
 * @returns a wrapper component that uses React's Suspense component to handle lazy loading of the
 * specified component. The wrapper component is responsible for rendering the lazy-loaded component
 * with the provided props.
 */
import React from "react";

export const LazyLoader = (Component) => {
  const WrapperComponent = (props) => (
    <React.Suspense fallback={null}>
      <Component {...props} />
    </React.Suspense>
  );

  WrapperComponent.displayName = `LazyLoader(${
    Component.displayName || Component.name
  })`;

  return WrapperComponent;
};

LazyLoader.prototype = {};
